<template>
  <b-card class="mb-3">
    <div class="d-flex mb-3">
      <div class="flex-grow-1">
        <h5 class="mb-1">Orders</h5>
        <div>Orders overview</div>
      </div>
      <b-button variant="link" class="p-0 text-muted" @click="$emit('refresh')">
        <i class="fa fa-redo"></i>
      </b-button>
    </div>

    <b-row>
      <b-col cols="6" class="text-center">
        <div
          class="width-50 height-50 bg-primary-transparent-2 rounded-circle d-flex align-items-center justify-content-center mb-2 ml-auto mr-auto"
        >
          <i class="fa fa-calendar-day fa-lg text-primary"></i>
        </div>
        <div class="font-weight-600 text-dark">{{ today }}</div>
        <div class="fs-13px">Today</div>
      </b-col>

      <b-col cols="6" class="text-center">
        <router-link :to="{ name: 'orders.to-pack' }">
          <div
            class="width-50 height-50 bg-primary-transparent-2 rounded-circle d-flex align-items-center justify-content-center mb-2 ml-auto mr-auto"
          >
            <i class="fa fa-box fa-lg text-primary"></i>
          </div>
        </router-link>
        <div class="font-weight-600 text-dark">{{ toPack }}</div>
        <div class="fs-13px">To Pack</div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'DashboardOrdersWidget',
  props: {
    today: { type: Number, required: true },
    toPack: { type: Number, required: true },
  },
}
</script>
