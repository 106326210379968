<template>
  <b-card no-body class="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-pink" style="min-height: 202px;">
    <div class="card-img-overlay mb-n4 mr-n4 d-flex" style="bottom: 0; top: auto;">
      <c-img src="/img/icon/browser.svg" alt class="ml-auto d-block mb-n3" style="max-height: 105px" />
    </div>

    <b-card-body class="position-relative">
      <h5 class="text-white-transparent-8 mb-3 fs-16px">
        New Reviews
      </h5>
      <h3 class="text-white mt-n1">{{ pendingReviews }}</h3>
      <div class="progress bg-black-transparent-5 mb-2" style="height: 6px">
        <div class="progrss-bar progress-bar-striped bg-white" style="width: 100%" />
      </div>
      <div>
        <router-link
          :to="{ name: 'pending-reviews' }"
          class="text-white d-flex align-items-center text-decoration-none"
        >
          Handle Reviews
          <i class="fa fa-chevron-right ml-2 text-white-transparent-5" />
        </router-link>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'DashboardReviewsWidget',
  props: { pendingReviews: { type: Number, required: true } },
}
</script>
