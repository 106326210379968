<template>
  <div id="content" class="app-content">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="flex-fill font-weight-600 fs-16px">
          Sales Trend
        </div>

        <div class="d-flex">
          <b-button v-for="interval in intervals"
                    class="mr-1"
                    @click="selectedInterval = interval"
                    :pressed="selectedInterval === interval"
                    variant="outline-primary">
            {{ interval }}
          </b-button>

          <div class="border-right mr-1"/>

          <b-button v-for="type in types"
                    class="mr-1"
                    @click="selectedType = type"
                    :pressed="selectedType === type"
                    variant="outline-info">
            {{ type }}
          </b-button>
        </div>
      </div>

      <div class="chart mb-2">
        <line-chart :data="chartData" :options="chartOptions" class="w-100" style="height: 360px"/>
      </div>
    </b-card>
  </div>
</template>

<script>
import {salesTrend} from "@/api";
import moment from 'moment'
import LineChart from "@/components/vue-chartjs/LineChart";

export default {
  name: 'SalesTrendPage',
  components: {LineChart},

  data() {
    return {
      selectedInterval: '7 days',
      intervals: ['7 days', '30 days', 'past 3 months'],
      selectedType: 'Daily',
      types: ['Daily', 'Weekly', 'Monthly'],
      currentValues: {},
      previousValues: {},
    }
  },

  mounted() {
    this.getNetSalesPerInterval(moment().subtract(3, 'months'));
  },

  computed: {
    currentInterval() {
      const dateFrom = moment();

      if (this.selectedInterval === '7 days') dateFrom.subtract(7, 'days');
      else if (this.selectedInterval === '30 days') dateFrom.subtract(30, 'days');
      else dateFrom.subtract(3, 'months');

      return {dateFrom, dateTo: moment()}
    },

    chartData() {
      const resultCurrent = {};
      const resultPrevious = {};

      if (this.selectedType === 'Weekly') {
        let currentWeekStartDate = moment(this.currentInterval.dateFrom).startOf('week');

        while (currentWeekStartDate <= this.currentInterval.dateTo) {
          let currentWeekEndDate = moment(currentWeekStartDate).endOf('week'), weekSumCurrent = 0, weekSumPrevious = 0;

          for (let currentDate = moment(currentWeekStartDate); currentDate <= currentWeekEndDate; currentDate.add(1, 'day')) {
            const formattedCurrent = currentDate.format('YYYY-MM-DD');
            const formattedPrevious = currentDate.clone().subtract(1, 'year').format('YYYY-MM-DD');

            this.currentValues[formattedCurrent] ? weekSumCurrent += this.currentValues[formattedCurrent] : null;
            this.previousValues[formattedPrevious] ? weekSumPrevious += this.previousValues[formattedPrevious] : null;
          }

          resultCurrent[`${currentWeekStartDate.format('YYYY-MM-DD')} - ${currentWeekEndDate.format('YYYY-MM-DD')}`] = weekSumCurrent;
          resultPrevious[`${currentWeekStartDate.format('YYYY-MM-DD')} - ${currentWeekEndDate.format('YYYY-MM-DD')}`] = weekSumPrevious;
          currentWeekStartDate.add(1, 'week');
        }
      } else if (this.selectedType === 'Monthly') {
        let currentDate = moment(this.currentInterval.dateFrom).startOf('month');

        while (currentDate <= this.currentInterval.dateTo) {
          const endOfMonth = moment(currentDate).endOf('month');
          let formattedMonth = currentDate.format('YYYY-MM'), monthSumCurrent = 0, monthSumPrevious = 0;

          for (let dayOfMonth = moment(currentDate); endOfMonth >= dayOfMonth; dayOfMonth.add(1, 'day')) {
            const formattedCurrent = dayOfMonth.format('YYYY-MM-DD');
            const formattedPrevious = dayOfMonth.clone().subtract(1, 'year').format('YYYY-MM-DD');

            this.currentValues[formattedCurrent] ? monthSumCurrent += this.currentValues[formattedCurrent] : null;
            this.previousValues[formattedPrevious] ? monthSumPrevious += this.previousValues[formattedPrevious] : null;
          }

          resultCurrent[formattedMonth] = monthSumCurrent;
          resultPrevious[formattedMonth] = monthSumPrevious;
          currentDate.add(1, 'month');
        }
      } else if (this.selectedType === 'Daily') {
        let currentDate = moment(this.currentInterval.dateFrom);

        while (currentDate <= this.currentInterval.dateTo) {
          const formattedCurrent = currentDate.format('YYYY-MM-DD');
          const formattedPrevious = currentDate.clone().subtract(1, 'year').format('YYYY-MM-DD');

          resultCurrent[formattedCurrent] = this.currentValues[formattedCurrent] ? this.currentValues[formattedCurrent] : 0;
          resultPrevious[formattedCurrent] = this.previousValues[formattedPrevious] ? this.previousValues[formattedPrevious] : 0;
          currentDate.add(1, 'day');
        }
      }

      return {
        labels: Object.keys(resultCurrent),
        datasets: [
          {
            label: 'Target',
            color: '#640DF3', backgroundColor: 'rgba(121, 56, 224, .2)', borderColor: '#640DF3',
            pointBackgroundColor: '#ffffff', pointBorderWidth: 2, pointRadius: 4,
            pointHoverBackgroundColor: '#ffffff', pointHoverBorderColor: '#640DF3', pointHoverRadius: 6, pointHoverBorderWidth: 2,
            data: Object.values(resultPrevious).map(value => Math.round(value * 1.15 * 100) / 100),
          },
          {
            label: 'Actual',
            color: '#0cd096', backgroundColor: 'rgba(12, 208, 150, .2)', borderColor: '#0cd096',
            pointBackgroundColor: '#ffffff', pointBorderWidth: 2, pointRadius: 4,
            pointHoverBackgroundColor: '#ffffff', pointHoverBorderColor: '#0cd096', pointHoverRadius: 6, pointHoverBorderWidth: 2,
            data: Object.values(resultCurrent),
          },
        ],
      }
    },

    chartOptions() {
      return {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 10000,
              },
            },
          ],
        },
      }
    },
  },

  methods: {
    getNetSalesPerInterval(dateFrom) {
      salesTrend.netSalesPerInterval(dateFrom.format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
        .then((data) => {
          this.currentValues = data.current;
          this.previousValues = data.previous;

          console.log(Object.values(data.previous).reduce((partialSum, a) => partialSum + a, 0))
        })
    },
  }
}
</script>
