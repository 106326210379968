<template>
  <b-card>
    <div class="d-flex align-items-center mb-2">
      <div class="flex-fill font-weight-600 fs-16px">
        Sales Trend
      </div>
    </div>

    <div class="d-flex align-items-center mb-4 h3">
      <div class="invisible">52.85%</div>
      <small class="fw-400 ml-auto text-danger invisible">-7%</small>
    </div>

    <div>
      <div class="fs-13px font-weight-600 mb-3">Earnings</div>
      <div class="chart mb-2">
        <!-- <pre>{{ data }}</pre> -->
        <line-chart :data="data" :options="options" class="w-100" style="height: 190px" />
      </div>
      <div class="d-flex align-items-center">
        <i class="fa fa-square text-teal mr-2"></i>
        <span class="fs-12px mr-4">Actual</span>
        <i class="fa fa-square text-indigo mr-2 ml-auto"></i>
        <span class="fs-12px">Target</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import LineChart from '../../components/vue-chartjs/LineChart'
import moment from 'moment'

const DAYS = () => {
  const days = []
  const dateStart = moment().startOf('month')
  const dateEnd = moment().add(7, 'days')
  while (dateEnd.diff(dateStart, 'days') > 0) {
    days.push(dateStart.format('D'))
    dateStart.add(1, 'days')
  }
  return days
}

export default {
  name: 'DashboardSalesTrendWidget',
  components: { LineChart },
  props: { salesTrend: { type: Object, required: true } },
  computed: {
    data() {
      return {
        labels: [...DAYS()],
        datasets: [
          {
            label: 'Target',
            color: '#640DF3',
            backgroundColor: 'transparent',
            borderColor: '#640DF3',
            borderWidth: 2,
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 2,
            pointRadius: 4,
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#640DF3',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 2,
            data: this.salesTrend.target,
          },
          {
            label: 'Actual',
            color: '#0cd096',
            backgroundColor: 'rgba(12, 208, 150, .2)',
            borderColor: '#0cd096',
            borderWidth: 2,
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 2,
            pointRadius: 4,
            pointHoverBackgroundColor: '#ffffff',
            pointHoverBorderColor: '#0cd096',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 2,
            data: this.salesTrend.actual,
          },
        ],
      }
    },
    options() {
      return {
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
                stepSize: 1000,
              },
            },
          ],
        },
      }
    },
  },
}
</script>
