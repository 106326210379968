<template>
  <div id="content" class="app-content">
    <b-card class="mb-3">
      <b-row v-for="(type, index) in types" class="align-items-center mb-2">
        <b-col cols="2">
          <strong>{{ type.name }}</strong>
        </b-col>

        <b-col cols="4">
          {{ type.description }}
        </b-col>

        <b-col cols="1">
          <b-form-select v-model="types[index].month" :options="filteredMonths(index)"/>
        </b-col>

        <b-col cols="1">
          <b-form-select v-model="types[index].year" :options="years"/>
        </b-col>

        <b-col cols="4">
          <b-button variant="primary"
                    class="mr-2"
                    @click="downloadByMonth(type)"
                    :disabled="isLoading || (currentYear === type.year && currentMonth === type.month)">
            Download for {{ type.month }} {{ type.year }}
          </b-button>

          <b-button variant="primary"
                    :disabled="isLoading || currentYear === type.year"
                    @click="downloadReport(type.name,type.year + '-01-01',type.year + '-12-31')">
            Download for {{ type.year }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col cols="3">
          <b-form-select v-model="selectedType" :options="types.map((type) => type.name)"/>
        </b-col>

        <b-col cols="3">
          <b-form-datepicker v-model="dateFrom" :start-weekday="1" @input="dateTo = null" :max="new Date()"/>
        </b-col>

        <b-col cols="3">
          <b-form-datepicker v-model="dateTo" :min="dateFrom" :max="new Date()" :start-weekday="1"/>
        </b-col>

        <b-col cols="3">
          <b-button variant="primary" class="mr-2" :disabled="!this.dateFrom || !this.dateTo || isLoading"
                    @click="downloadReport(selectedType, dateFrom, dateTo)">
            Download for selected interval
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import {report} from '@/api'
import {downloadFile} from "@/helpers/files";

export default {
  name: 'ReportsPage',

  data() {
    return {
      types: [
        {name: 'Salesreport', description: 'Documentation of all sales from date to date', month: null, year: null},
        {name: 'Returnreport', description: 'Documentation of all returns from date to date', month: null, year: null},
        {
          name: 'Not redeemedreport',
          description: 'Documentation of all giftcards that is not redeemed from date to date',
          month: null,
          year: null
        },
        {name: 'By interval', description: 'Superreport', month: null, year: null},
      ],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectedType: 'Salesreport',
      dateFrom: null,
      dateTo: null,
      isLoading: false,
      currentYear: new Date().getFullYear(),
      currentMonth: null
    }
  },

  created() {
    this.currentMonth = this.months[new Date().getMonth()];

    for (let i = 0; i < this.types.length; i++) {
      this.types[i].year = this.currentYear;
      this.types[i].month = this.currentMonth;
    }
  },

  computed: {
    years() {
      const yearList = [];
      const year = this.currentYear;

      for (let i = 0; i < 10; i += 1) yearList.push(Number(year) - i);

      return yearList;
    }
  },

  methods: {
    downloadByMonth(type) {
      const day = moment([type.year, this.months.indexOf(type.month), 1]);

      this.downloadReport(type.name, day.format('YYYY-MM-DD'), day.endOf('month').format('YYYY-MM-DD'))
    },

    async downloadReport(type, dateFrom, dateTo) {
      this.isLoading = true;

      const blob = await report.download(type, dateFrom, dateTo)

      downloadFile(blob, type + ' ' + dateFrom + ' - ' + dateTo + '.csv');

      this.isLoading = false;
    },

    filteredMonths(index) {
      if (this.types[index].year === this.currentYear) {
        const monthIndex = new Date().getMonth();

        if (this.months.indexOf(this.types[index].month) > monthIndex) {
          this.types[index].month = this.months[monthIndex];
        }

        return this.months.slice(0, monthIndex + 1);
      }

      return this.months;
    }
  }
}
</script>
