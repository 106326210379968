<template>
  <div id="content" class="app-content">
    <h1 class="page-header mb-3">
      Hi, {{ $store.getters['auth/user'].first_name }}.
      <small>here's what's happening today.</small>
    </h1>
    <b-row>
      <b-col xl="6">
        <dashboard-sales-trend-widget :sales-trend="salesTrend" />
      </b-col>

      <b-col xl="6">
        <div class="row">
          <div class="col-sm-6">
            <dashboard-users-widget :user-count="userCount" @refresh="loaded" />
            <dashboard-orders-widget :today="ordersToday" :to-pack="ordersToPack" @refresh="loaded" />
          </div>

          <div class="col-sm-6">
            <dashboard-reviews-widget :pending-reviews="pendingReviews" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { base } from '@/api'
import DashboardSalesTrendWidget from './DashboardSalesTrendWidget.vue'
import DashboardOrdersWidget from './DashboardOrdersWidget.vue'
import DashboardUsersWidget from './DashboardUsersWidget.vue'
import DashboardReviewsWidget from './DashboardReviewsWidget.vue'

export default {
  name: 'DashboardPage',
  components: {
    DashboardSalesTrendWidget,
    DashboardOrdersWidget,
    DashboardUsersWidget,
    DashboardReviewsWidget,
  },
  data() {
    return {
      salesTrend: {},
      userCount: 0,
      ordersToday: 0,
      ordersToPack: 0,
      pendingReviews: 0,
    }
  },
  async beforeMount() {
    this.loaded()
  },
  methods: {
    async loaded() {
      const { data } = await base.dashboard()

      this.salesTrend = data.sales_trend
      this.userCount = data.user_count
      this.ordersToday = data.orders_today
      this.ordersToPack = data.orders_to_pack
      this.pendingReviews = data.pending_reviews
    },
  },
}
</script>
