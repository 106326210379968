<template>
  <b-card class="mb-3">
    <div class="d-flex mb-3">
      <div class="flex-grow-1">
        <h5 class="mb-1">Total Users</h5>
        <div>User account registration</div>
      </div>
      <b-button variant="link" class="p-0 text-muted" @click="$emit('refresh')">
        <i class="fa fa-redo"></i>
      </b-button>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1">
        <h3 class="mb-1">{{ usersFormatted }}</h3>
      </div>
      <div
        class="width-50 height-50 bg-primary-transparent-2 rounded-circle d-flex align-items-center justify-content-center"
      >
        <i class="fa fa-user fa-lg text-primary"></i>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'DashboardUsersWidget',
  props: { userCount: { type: Number, required: true } },
  computed: {
    usersFormatted() {
      return new Intl.NumberFormat('nb-NO').format(this.userCount)
    },
  },
}
</script>
